// import '../assets/sass/main.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import logoImage from './../assets/img/favicon-16x16.webp';
import loadable from '@loadable/component';
const SideBar = loadable(() => import('./Sidebar'));
const Footer = loadable(() => import('./Footer'));
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
    this.titleSeo = props.titleSeo;
    this.descriptionSeo = props.descriptionSeo;
    this.keywords = props.keywords;
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children, fullMenu } = this.props;
    const { isPreloaded } = this.state;
    return (
      <StaticQuery
        query={graphql`
          {
            mongodbCloserDevCmsPages(
              siteId: { eq: "5dac524cf761b3073f3ee47f" }
            ) {
              content {
                seo {
                  titleSeo
                  description
                  keywords
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            <Helmet
              title={this.titleSeo || data.mongodbCloserDevCmsPages.content.seo.titleSeo}
              meta={[
                {
                  name: 'description',
                  content:
                    this.descriptionSeo || data.mongodbCloserDevCmsPages.content.seo.description,
                },
                {
                  name: 'keywords',
                  content: this.keywords || data.mongodbCloserDevCmsPages.content.seo.keywords,
                },
                { name: 'image', content: logoImage },
              ]}
            >
              <html lang='pt' />
              <script
                defer
                dangerouslySetInnerHTML={{
                  __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-5BPKC55');
                            `,
                }}
              ></script>
            </Helmet>
            <div
              className={isPreloaded ? ' main-body  is-preload' : ' main-body'}
            >
              <div id='page-wrapper'>
                <SideBar fullMenu={fullMenu} />
                {children}
                <Footer />
              </div>
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
